import React from "react";
import AssessmentPreview from "assets/images/recordedContent/assessment.gif";
import FormPreview from "assets/images/recordedContent/form.gif";

import { QUESTIONNAIRE_TYPES, SETTINGS_KEYS } from "../recordedContent.data";
import { EXAM_HINT_OPTIONS_MAP } from "./pages/Settings/components/ExamHint/constants/constants";

export const EXAM_STEPS = ["Templates", "Questionnaire", "Settings"];

export const EXAM_PAGES = {
  TEMPLATE: 0,
  QUESTIONNAIRE: 1,
  SETTINGS: 2,
};

export const examCreationFormKeys = {
  template: "template",
  questions: "questions",
};

export const EXAM_CREATION_TOOLTIPS_AUTOMATIC_SCORING = {
  [SETTINGS_KEYS.totalMarks]:
    "This will be the total score that a student can attain in this exam.",
  [SETTINGS_KEYS.correctAnswerMark]:
    "This will be the score that a student will get if they answer a question correctly.",
  [SETTINGS_KEYS.incorrectAnswerMark]:
    "This will be the score that a student will get if they answer a question incorrectly.",
  [SETTINGS_KEYS.passingMarks]:
    "This will set a passing limit for the student. If they get this much score in the exam, they’ll be able to pass the exam.",
  [SETTINGS_KEYS.isReattemptAllowed]:
    "This will allow the student to retake the exam and you can choose how many times can they retake the exam.",
  [SETTINGS_KEYS.duration]:
    "This will apply a timer on the exam, and the students will have to submit their responses within that stipulated time. All responses will be automatically submitted once time runs out.",
};

export const EXAM_CREATION_TOOLTIPS_MANUAL_SCORING = {
  [SETTINGS_KEYS.isReattemptAllowed]: (
    <div>
      This will allow the student to retake the exam and you can choose how many
      times can they retake the exam. Please note,{" "}
      <strong>
        you’ll be required to re-evaluate the submissions for each attempt
      </strong>
      .
    </div>
  ),
};

export const creationPreviews = {
  [QUESTIONNAIRE_TYPES.EXAM]: AssessmentPreview,
  [QUESTIONNAIRE_TYPES.FORM]: FormPreview,
};

export const creationTitles = {
  [QUESTIONNAIRE_TYPES.EXAM]: "Exam",
  [QUESTIONNAIRE_TYPES.FORM]: "Form",
};

export const CREATION_TITLE_LOWER_CASE = Object.fromEntries(
  Object.entries(creationTitles).map(([k, v]) => [k, String(v).toLowerCase()])
);

export const questionairreCreationQueryParams = {
  creationType: "creationType",
  creationPage: "creationPage",
  questionairreUuid: "questionairreUuid",
  title: "title",
};

export const settingsDummyKeys = {
  customDuration: "customDuration", // dummy key on front-end
};

export const initialFormState = {
  [SETTINGS_KEYS.isReattemptAllowed]: true,
  [SETTINGS_KEYS.duration]: 0,
  [SETTINGS_KEYS.enable_hints]: EXAM_HINT_OPTIONS_MAP.NO.value,
};

export const formPointsToRemember = [
  "Students will be able to edit all their form responses until they complete the course.",
  "You may find all the form responses for a student in course analytics.",
];

export const examDurationCustomKeys = {
  custom: "custom",
};

export const examDurationOptions = [
  {
    id: 0,
    label: "Select exam duration",
    value: "",
  },
  {
    id: 1,
    label: "30 minutes",
    value: 30,
  },
  {
    id: 2,
    label: "45 minutes",
    value: 45,
  },
  {
    id: 3,
    label: "60 minutes",
    value: 60,
  },
  {
    id: 4,
    label: "120 minutes",
    value: 120,
  },
  {
    id: 5,
    label: "Custom",
    value: examDurationCustomKeys.custom,
  },
];
