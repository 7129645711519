import React, { Suspense } from "react";
import ExlyLoader from "ui/modules/ExlyLoader";

/**
 * Lazily load the mentioned component which resides in the page directory
 * This method will be used in routes so that the files are loaded only
 * When users are on that route
 */
export const LazyLoadRoutes = ({ LazyElement, props }) => {
  // ui/pages/AddEditEmailDrip/pages/EmailDripReview/EmailDripReview
  // Wrapping around the suspense component is mandatory
  return (
    <Suspense fallback={<ExlyLoader />}>
      <LazyElement {...props} />
    </Suspense>
  );
};
