import {
  EXLY_GLOBAL__SET_PAGE_TITLE,
  EXLY_GLOBAL__SET_SHOW_APP_BAR,
  EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV,
  EXLY_GLOBAL__RESET,
  EXLY_GLOBAL__SET_NAV_BAR_SECONDARY_ACTION,
  EXLY_GLOBAL__PUSH_NOTIFICATION,
  EXLY_GLOBAL__POP_NOTIFICATION,
  EXLY_GLOBAL__SET_SHOW_PAGE_TITLE,
  EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
  EXLY_GLOBAL__BULK_IMPORT_ID,
} from "./actions";

const initialState = {
  pageTitle: "",
  showAppBar: true,
  showBottomNav: true,
  navBarSecondaryAction: {
    show: false,
    label: "",
    btnProps: {},
  },
  notificationList: {},
  showPageTitle: true,
  customBackAction: null,
  bulkImportUuids: {
    lead: "",
    customer: "",
  },
  expiredCreditsData: {},
};

const globalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_GLOBAL__SET_PAGE_TITLE: {
      return { ...state, pageTitle: payload };
    }
    case EXLY_GLOBAL__SET_SHOW_APP_BAR: {
      return { ...state, showAppBar: payload };
    }
    // @quashid TODO: Later
    case EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION: {
      return { ...state, customBackAction: payload };
    }
    case EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV: {
      return { ...state, showBottomNav: payload };
    }
    case EXLY_GLOBAL__RESET: {
      return initialState;
    }
    case EXLY_GLOBAL__SET_NAV_BAR_SECONDARY_ACTION: {
      return { ...state, navBarSecondaryAction: payload };
    }
    case EXLY_GLOBAL__PUSH_NOTIFICATION: {
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          ...payload,
        },
      };
    }
    case EXLY_GLOBAL__POP_NOTIFICATION: {
      const newList = { ...state.notificationList };
      delete newList[payload.id];
      return { ...state, notificationList: newList };
    }
    case EXLY_GLOBAL__SET_SHOW_PAGE_TITLE: {
      return { ...state, showPageTitle: payload };
    }
    case EXLY_GLOBAL__BULK_IMPORT_ID: {
      const newBulkImportData = { ...state.bulkImportUuids, ...payload };
      return {
        ...state,
        bulkImportUuids: newBulkImportData,
      };
    }
    default: {
      return state;
    }
  }
};

export default globalReducer;
