import { accepted_image_formats } from "constants/recordedContent";
import { getDotFormats } from "utils/fileUtils";

export const image_popup_tab_ids = {
  upload: 0,
  paste_url: 1,
};

export const image_popup_tabs = [
  {
    label: "Upload",
    id: image_popup_tab_ids.upload,
  },
  {
    label: "Paste URL",
    id: image_popup_tab_ids.paste_url,
  },
];

export const max_file_name_length = 100;
export const min_file_name_length = 3;

export const dot_accepted_formats = getDotFormats(accepted_image_formats);
