import React, { useEffect, useRef, useState } from "react";
import classes from "../Style.module.css";
import { InfoOutlined } from "@material-ui/icons";
import { InteractiveTooltip } from "@my-scoot/component-library-legacy";
import { scrollMainContentToTop } from "common/util";
import classnames from "classnames";
import { getWhatsNextInfo } from "features/Onboarding/modules/WhatsNext/utils/whatsNext.utils";

export const InteractiveTooltipContext = React.createContext();

export default function InteractiveTooltipWrapper({
  children,
  onClose,
  forceClose,
}) {
  const [currentActive, setCurrentActive] = React.useState(-1);
  const [tooltipWeights, setTooltipWeights] = React.useState([]);
  const [autoPreview, setAutoPreview] = React.useState(false);
  const [shownTooltips, setShownTooltips] = React.useState([]);

  const nextSteps = getWhatsNextInfo();

  const provided = React.useMemo(() => ({
    currentActive,
    tooltipWeights,
    autoPreview,
    setTooltipWeights: (e) => setTooltipWeights(e),
    setCurrentActive: (active) => setCurrentActive(active),
    setAutoPreview: (e) => setAutoPreview(e),
    onClose,
  }));

  useEffect(() => {
    if (forceClose) return;

    if (!nextSteps || !nextSteps["has_live_listing"]) {
      setTimeout(
        () => {
          let startFrom = 0;

          const diff = tooltipWeights.filter((e) => !shownTooltips.includes(e));

          if (diff.length > 0) {
            startFrom = tooltipWeights.findIndex((e) => e === diff[0]);
          }

          setCurrentActive(tooltipWeights[startFrom]);
          setAutoPreview(true);
        },
        autoPreview ? 0 : 2000
      );
    }
  }, [tooltipWeights]);

  React.useEffect(() => {
    if (forceClose) {
      setCurrentActive(-1);
    }
  }, [forceClose]);

  useEffect(() => {
    if (currentActive > -1) {
      if (!shownTooltips.includes(currentActive)) {
        setShownTooltips([...shownTooltips, currentActive]);
      }
    }
  }, [currentActive]);

  return (
    <InteractiveTooltipContext.Provider value={provided}>
      {currentActive > -1 && autoPreview && (
        <div className={classes.toolTipBackdrop}></div>
      )}
      {children}
    </InteractiveTooltipContext.Provider>
  );
}

const Tooltip = ({
  content,
  weight,
  forceClose,
  rightAlign,
  leftAlign,
  bottom,
  className,
  spotLightElementClassName,
}) => {
  const {
    currentActive,
    setCurrentActive,
    tooltipWeights,
    setTooltipWeights,
    autoPreview,
    setAutoPreview,
    onClose,
  } = React.useContext(InteractiveTooltipContext);

  const tooltipWeightsRef = useRef(null);

  let tooltipPlacement;
  if (bottom) tooltipPlacement = "bottom";
  if (rightAlign) tooltipPlacement = "left";
  else if (leftAlign) tooltipPlacement = "right";

  const isMultiStep = Array.isArray(content);

  // @dev This state is introduced to accomodate multiple steps in a tooltip. It keeps track of the current tooltip step to be shown from content array.
  const [currentStep, setCurrentStep] = useState(0);

  React.useEffect(() => {
    if (!tooltipWeights.includes(weight)) {
      const newWeights = [...tooltipWeights, weight].sort((a, b) => a - b);
      tooltipWeightsRef.current = newWeights;
      setTooltipWeights(newWeights);
    }
  }, [weight, tooltipWeights]);

  useEffect(() => {
    // update tooltipWeights on unmount
    return () => {
      let index = tooltipWeightsRef.current?.indexOf(weight);
      if (index >= 0) {
        const updatedTooltipsWeights = [...tooltipWeights];
        updatedTooltipsWeights.splice(index, 1);
        setTooltipWeights(updatedTooltipsWeights);
      }
    };
  }, []);

  React.useEffect(() => {
    if (currentActive > -1) {
      const childNodes = document.querySelectorAll(`#tooltip-${currentActive}`);
      const childNode = childNodes?.[childNodes.length - 1];

      if (childNode && autoPreview) {
        const parentNode = childNode.closest(
          spotLightElementClassName
            ? `.${spotLightElementClassName}`
            : ".exly--MuiPaper-elevation1"
        );

        if (parentNode) {
          parentNode.style.zIndex = "1102";
          parentNode.style.position = "relative";
        }
      }
      if (childNode) {
        document
          .querySelector(`#tooltip-${currentActive}`)
          .scrollIntoView({ block: "center" });
      }
      document.querySelector("#mainLayout").style.overflow = "hidden";
    }

    return () => {
      if (currentActive > -1) {
        const childNodes = document.querySelectorAll(
          `#tooltip-${currentActive}`
        );
        const childNode = childNodes?.[childNodes.length - 1];

        if (childNode) {
          const parentNode = childNode.closest(
            spotLightElementClassName
              ? `.${spotLightElementClassName}`
              : ".exly--MuiPaper-elevation1"
          );

          if (parentNode) {
            parentNode.style.zIndex = "";
            parentNode.style.position = "";
          }
        }
      }

      document.querySelector("#mainLayout").style.overflow = "auto";
    };
  }, [currentActive, tooltipWeights]);

  React.useEffect(() => {
    if (forceClose) {
      // remove active tooltip
      setCurrentActive(-1);
    }
  }, [forceClose]);

  const handleClose = (event) => {
    event.stopPropagation();
    setCurrentActive(-1);
    setAutoPreview(false);
    if (onClose) onClose();
  };

  const handleClick = (event) => {
    event.stopPropagation();

    // @dev handling the intermediate step iteration of the mutliple step tooltip
    if (isMultiStep && currentStep + 1 < content.length) {
      setCurrentStep((prev) => prev + 1);
      return;
    }

    const nextWeightIndex = tooltipWeights.indexOf(weight) + 1;
    const isLastStep =
      tooltipWeights.indexOf(String(currentActive)) ===
      tooltipWeights.length - 1;

    setCurrentActive(
      nextWeightIndex > -1 && autoPreview ? tooltipWeights[nextWeightIndex] : -1
    );

    if ((isLastStep || !autoPreview) && onClose) onClose();

    if (autoPreview) {
      document.querySelector(`#tooltip-${weight}`).scrollIntoView({
        block: tooltipWeights.length - 1 > nextWeightIndex ? "center" : "start",
      });
    }

    if (nextWeightIndex > tooltipWeights.length - 1 && autoPreview) {
      scrollMainContentToTop();
    }
  };

  let primaryBtnText = "Next";
  // @dev if not autoPreview or last step of the autoPreview
  if (
    tooltipWeights.indexOf(String(currentActive)) ===
      tooltipWeights.length - 1 ||
    !autoPreview
  ) {
    // @dev if not multistep or last step of multiStep
    if (!isMultiStep || currentStep + 1 == content.length) {
      primaryBtnText = "Got it";
    }
  }

  return (
    <InteractiveTooltip
      open={currentActive == weight}
      title={!isMultiStep ? content : content[currentStep]}
      primaryBtnText={primaryBtnText}
      onCloseClick={handleClose}
      onPrimaryBtnClick={handleClick}
      totalSteps={0}
      placement={tooltipPlacement}
      tooltipClasses={{
        popper: classes.interactiveToolTipPopper,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
      }}
      enterDelay={0}
      leaveDelay={0}
      TransitionComponent={({ children }) => children}
    >
      <span
        className={classnames(classes.interactiveTooltipHolder, className)}
        id={`tooltip-${weight}`}
        onClick={(e) => e.stopPropagation()}
      >
        <span>
          <InfoOutlined
            style={{ width: "18px", cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              // For MultiStep tooltip, resetting current step to 0 to start from beginning everytime.
              setCurrentStep(0);
              setCurrentActive(tooltipWeights[tooltipWeights.indexOf(weight)]);
              setAutoPreview(false);
            }}
          />
        </span>
      </span>
    </InteractiveTooltip>
  );
};

InteractiveTooltipWrapper.Tooltip = Tooltip;
