import React from "react";

import BackIcon from "@material-ui/icons/ArrowBackRounded";

import { Backdrop, Drawer, IconButton } from "@material-ui/core";
import { Button, Progress } from "@my-scoot/component-library-legacy";

import PhoneMockScreen from "assets/vectors/phone_mock_screen.svg";
import CloseIcon from "@material-ui/icons/CloseRounded";

import styles from "./helperComponents.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyModal from "common/Components/ExlyModal";
import {
  creationPreviews,
  creationTitles,
  EXAM_STEPS,
  formPointsToRemember,
} from "../../examCreation.data";
import InteractiveTooltipWrapper from "schedule-v2/components/InteractiveTooltip";
import classnames from "classnames";
import { Field, useFormState } from "react-final-form";
import { QUESTIONNAIRE_FORM_KEYS } from "schedule-v2/components/recorded-content/recordedContent.data";
import { is_empty } from "utils/validations";
import { trimText } from "features/Common/modules/DataTypes/utils/strings";

export const Header = ({
  lessonName,
  title,
  steps,
  activeStep,
  onClick,
  btnText = "Next",
  onClickSecondaryBtn,
  onStepChange,
  secondarybtnText = "Preview",
  onBack,
  onClose,
  showSecondarybtn,
  creationType,
  loading,
  isOnboarding,
  disableFirstStep,
}) => {
  const isDesktop = useDesktopMediaQuery();

  const { values: formValues } = useFormState();
  const { [QUESTIONNAIRE_FORM_KEYS.questionairreUid]: questionairreUid } =
    formValues;

  const isQuestionnairePublished = !is_empty(questionairreUid);

  const stepsToRender = isQuestionnairePublished
    ? steps?.map((step) => ({
        title: step,
        completed: true,
        disabled: disableFirstStep && step == EXAM_STEPS[0],
      }))
    : steps;

  const ProgressComponent = (
    <Progress
      options={stepsToRender}
      activeStep={activeStep}
      variant="alternate"
      stepIconSize="large"
      hideProgress={true}
      onClick={onStepChange}
      nonLinear={isQuestionnairePublished}
    />
  );

  const CtaComponent = (
    <>
      {showSecondarybtn && (
        <Button color="secondary" onClick={onClickSecondaryBtn}>
          {secondarybtnText}
        </Button>
      )}
      <Button onClick={onClick} fullWidth={!showSecondarybtn} loading={loading}>
        {btnText}
      </Button>
    </>
  );

  return (
    <>
      <div
        className={classnames(styles.header, isOnboarding && styles.noPadding)}
      >
        <div className={styles.header__title}>
          <IconButton onClick={() => onClose(formValues)}>
            <BackIcon />
          </IconButton>
          <h1>
            <span>{trimText(lessonName, 10)}</span>/{" "}
            <span className={styles.type}>
              {title ? trimText(title, 15) : creationTitles[creationType]}
            </span>
          </h1>
        </div>

        {isDesktop && ProgressComponent}

        <div className={styles.cta}>
          {!isDesktop && (
            <>
              {showSecondarybtn && (
                <span className={styles.backcta} onClick={onBack}>
                  Back
                </span>
              )}
              <div>{CtaComponent}</div>
            </>
          )}
          {isDesktop && CtaComponent}
        </div>
      </div>
      {!isDesktop && (
        <div className={styles.header__mobile__progress}>
          {ProgressComponent}
        </div>
      )}
    </>
  );
};

export const CreationPreview = ({
  open,
  onClose,
  creationType,
  isOnboarding,
}) => {
  const isDesktop = useDesktopMediaQuery();

  const Content = (
    <>
      {isDesktop && (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}

      <div className={styles.preview_title_container}>
        {isDesktop && <div className={styles.preview_title}>Preview</div>}
        <div className={styles.preview_description}>
          This is how your exam will appear
        </div>
      </div>

      <div className={styles.preview_screen}>
        <img src={PhoneMockScreen} alt="" />
        <div className={styles.preview_gif}>
          <img src={creationPreviews[creationType]} alt="" />
        </div>
      </div>
    </>
  );

  return isDesktop ? (
    <Drawer
      classes={{
        root: styles.preview_root,
        paper: classnames(
          styles.drawerPaper,
          isOnboarding && styles.onboardingDrawerPaper
        ),
      }}
      anchor="right"
      open={open}
      onClose={onClose}
      BackdropComponent={(props) => (
        <Backdrop className={styles.backdrop} {...props} />
      )}
    >
      {Content}
    </Drawer>
  ) : (
    <ExlyModal
      open={open}
      onClose={onClose}
      title="Preview"
      primaryBtnText="Close"
      onPrimaryBtnClick={onClose}
    >
      {Content}
    </ExlyModal>
  );
};
export const Label = ({
  label,
  tooltip = undefined,
  weight = undefined,
  bottom = true,
  marginBotton = undefined,
  required = true,
  tooltipClassName = undefined,
}) => {
  return (
    <div
      className={classnames(
        "d-flex align-items-center",
        marginBotton && styles.label__root
      )}
    >
      <span className={styles.label}>
        {label}
        {required && <span>*</span>}
      </span>
      {tooltip && (
        <InteractiveTooltipWrapper.Tooltip
          weight={+weight}
          bottom={bottom}
          content={tooltip}
          className={classnames(styles.tooltip, tooltipClassName)}
          spotLightElementClassName={`settingsField`}
        />
      )}
    </div>
  );
};

export const FirstTimeFormPopup = ({ open, onClose }) => {
  return (
    <ExlyModal
      open={open}
      onClose={onClose}
      title="Points to remember"
      showSecondaryBtn={false}
      primaryBtnText="Okay"
      onPrimaryBtnClick={onClose}
      className={styles.modalRoot}
      modal_props={{
        modalPaperClassName: styles.modalPaperClassName,
        modalRootClassName: styles.modalRootClassName,
      }}
      primaryBtnProps={{
        fullWidth: true,
      }}
    >
      <div className={styles.firstTimeFormPopupRoot}>
        <ul className={styles.firstTimeFormPopupList}>
          {formPointsToRemember.map((point, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </ExlyModal>
  );
};

export const ConfirmationModal = ({ open, onClose, onConfirm }) => {
  return (
    <ExlyModal
      open={open}
      onClose={onClose}
      title="Confirm"
      showSecondaryBtn={false}
      primaryBtnText="Confirm"
      onPrimaryBtnClick={onConfirm}
      className={styles.modalRoot}
      modal_props={{
        modalPaperClassName: styles.modalPaperClassName,
      }}
      primaryBtnProps={{
        fullWidth: true,
      }}
    >
      Are you sure you want to exit? Progress will be lost.
    </ExlyModal>
  );
};

export const SettingsTooltipWrapper = ({ children }) => {
  return (
    <div className={classnames("settingsField", styles.settingsField)}>
      {children}
    </div>
  );
};

export const SettingsFieldWrapper = (props) => {
  return (
    <SettingsTooltipWrapper>
      <Field {...props} />
    </SettingsTooltipWrapper>
  );
};
