import { SECTION_UNLOCKING_TYPES } from "constants/recordedContent";

export const PAGES = {
  CURRICULUM: 0,
  COURSE_DETAILS: 1,
  PRICING: 2,
  REVIEW: 2,
};

export const RECORDED_CONTENT_CREATION_FORM_KEYS = {
  has_lesson_unlocking: "has_lesson_unlocking",
  unlocking_type: "unlocking_type",
  start_days_specific_time: "start_days_specific_time",
  start_time: "start_time",
  sub_categories: "sub_categories",
  categories: "categories",
  category_uuid: "uuid",
  lesson_uuid: "uuid",
  start_days: "start_days",
  lessons: "lessons",
  is_draft: "is_draft",
};

export const RECORDED_CONTENT_SECTION_KEYS = {
  title: "title",
  uuid: "uuid",
  sub_categories: "sub_categories",
  change_state: "change_state",
};

export const RECORDED_CONTENT_LESSON_KEYS = {
  contents: "contents",
  change_state: "change_state",
};

export const RECORDED_CONTENT_LESSON_CONTENT_KEYS = {
  type: "type",
  change_state: "change_state",
  uuid: "uuid",
};

// keys maintained only on frontend (mostly in case of react final form)
export const RECORDED_CONTENT_FORM_DUMMY_KEYS = {
  start_time_value: "start_time_value",
  unlocking: "unlocking",
};

export const RECORDED_CONTENT_DOM_IDS = {
  hasLessonUnlocking: "has_lesson_unlocking",
};

export const recordedContentTooltipWeights = {
  [PAGES.COURSE_DETAILS]: {
    title: 1,
    description: 2,
    unlocking: 3,
    postPreviousCompletion: 4,
    certificate: 5,
    validity: 6,
  },
};

export const UNLOCK_TYPE_DESCRIPTION = {
  [SECTION_UNLOCKING_TYPES.IMMEDIATELY]:
    "All sections will be unlocked from the start",
  [SECTION_UNLOCKING_TYPES.ON_SPECIFIC_DATE]:
    "Section(s) unlocks on its respective set date and time",
  [SECTION_UNLOCKING_TYPES.POST_START_DAYS]:
    "Section(s) unlocks as per set days and time from starting of course",
  [SECTION_UNLOCKING_TYPES.POST_PREVIOUS_COMPLETION]:
    "Section(s) unlocks only when preceding section is completed",
  [SECTION_UNLOCKING_TYPES.POST_INSTALLMENTS]:
    "Section(s) unlocks after linked instalments are paid",
};

// enum on the frontend used to determine the reason for deleting content in recorded content
export const CONTENT_DELETION_TYPES = {
  CERTIFICATE_POST_EXAMS: 1,
};

// video hosting platforms
export const RECORDED_CONTENT_VIDEO_SOURCE = {
  WISTIA: 1,
  VDOCIPHER_NON_DRM: 2,
  VDOCIPHER_DRM: 3,
  EXTERNAL_LINK: 4,
};

export const RECORDED_CONTENT_LEARN_MORE_REFS = {
  RECORDED_CONTENT_CREATION:
    "https://support.exlyapp.com/support/solutions/articles/84000347506-courses-recorded-videos-files-on-exly",
};
