import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    horizontalDivider: {
      borderBottom: "1.5px solid #D9D9D9",
    },
    root: {
      display: "flex",
      gap: "20px",
    },
    coverImage: {
      height: "68px",
      weight: "68px",
      overflow: "hidden",
    },
    contentContainer: {
      flex: 1,
    },
    price: {
      marginTop: "10px",
    },
    ctaContainer: {
      display: "flex",
      alignItems: "center",
      gap: ({ isDesktop }) => (isDesktop ? "12px" : "8px"),
    },
    divider: {
      background: "#D9D9D9",
      width: "1.5px",
      alignSelf: "stretch",
    },
    flex: {
      display: "flex",
      alignItems: "center",
    },
    dataContainer: {
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    titleContainer: {
      gap: "14px",
      display: "flex",
      flexWrap: "wrap",
    },
    title: {
      fontSize: "16px",
      lineHeight: "27px",
      whiteSpace: "nowrap",
    },
    options: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      gap: "12px",
      "& div": {
        padding: "4px 6px",
        border: "1.5px solid #006644",
        fontSize: "14px",
        borderRadius: "5px",
        lineHeight: "17px",
      },
    },
    iconButton: {
      gap: "8px",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 500,
      padding: 0,
    },
    editCta: {
      color: theme?.palette?.primary?.main,
      "& svg": {
        fontSize: "18.34px",
      },
    },
    deleteCta: {
      color: theme?.palette?.basic?.danger_red,
      "& svg": {
        fontSize: "20.58px",
      },
    },
    addDetailsCta: {
      marginTop: "20px",
    },
    totalInventory: {
      fontSize: "16px",
      lineHeight: "19px",
      marginTop: "10px",
      "& span": {
        color: theme?.palette?.basic?.danger_red,
      },
    },
  }),
  { name: "CreatorTool" }
);
