export const EXLY_GLOBAL__RESET = "@EXLY_GLOBAL/RESET";
export const EXLY_GLOBAL__SET_PAGE_TITLE = "@EXLY_GLOBAL/SET_PAGE_TITLE";
export const EXLY_GLOBAL__SET_SHOW_APP_BAR = "@EXLY_GLOBAL/SET_SHOW_APP_BAR";
export const EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV =
  "@EXLY_GLOBAL/SET_SHOW_BOTTOM_NAV";
export const EXLY_GLOBAL__SET_NAV_BAR_SECONDARY_ACTION =
  "@EXLY_GLOBAL/SET_NAV_BAR_SECONDARY_ACTION";
export const EXLY_GLOBAL__PUSH_NOTIFICATION =
  "@EXLY_GLOBAL/EXLY_GLOBAL__PUSH_NOTIFICATION";
export const EXLY_GLOBAL__POP_NOTIFICATION =
  "@EXLY_GLOBAL/EXLY_GLOBAL__POP_NOTIFICATION";
export const EXLY_GLOBAL__SET_SHOW_PAGE_TITLE =
  "@EXLY_GLOBAL/SET_SHOW_PAGE_TITLE";
export const EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION =
  "@EXLY_GLOBAL/CUSTOM_BACK_NAVIGATION"; // @quashid TODO: Later
export const EXLY_GLOBAL__BULK_IMPORT_ID = "@EXLY_GLOBAL/BULK_IMPORT_ID";
