export const INSTALLMENT_TYPES = {
  INITIAL_PAYMENT: 1,
  INSTALLMENT_CYCLE: 2,
};

export const ENTITY_CHANGE_STATES = {
  NO_CHANGE: 0,
  UPDATED: 1,
  NEW_ADDED: 2,
  DELETED: 3,
};

export const TIME_OF_INSTALLMENT = {
  DAYS_FROM_PURCHASE: 1,
  WEEKS_FROM_PURCHASE: 2,
  MONTHS_FROM_PURCHASE: 3,
};

export const INSTALLMENT_DURATION_UNIT = {
  [TIME_OF_INSTALLMENT.DAYS_FROM_PURCHASE]: 1,
  [TIME_OF_INSTALLMENT.WEEKS_FROM_PURCHASE]: 7,
  [TIME_OF_INSTALLMENT.MONTHS_FROM_PURCHASE]: 30,
};

export const PART_PAYMENT_FORM_KEYS = {
  payment_plan_type: "payment_plan_type",
  is_installment_international_price_enabled:
    "is_installment_international_price_enabled",
  installment_data: "installment_data", // installment_data is an array of added installments with keys corresponding to those listed in the INSTALLMENTS_KEYS enum below
  installment_name: "installment_name",
  installment_price: "installment_price",
  installment_price_international: "installment_price_international",
  payment_duration: "payment_duration",
  payment_duration_choice: "payment_duration_choice",
  priority: "priority",
};

// keys maintained on frontend
export const PART_PAYMENT_DUMMY_FORM_KEYS = {
  change_state: "change_state",
};

export const PART_PAYMENT_TOOLTIPS = {
  feature:
    "Enabling this will give customers an option to divide their payments according to this payment plan instead of having to pay everything in one go. This payment plan will be visible when they are making a booking.",
};

export const INSTALLMENTS_KEYS = {
  uuid: "uuid",
  installment_price: "installment_price",
  installment_price_international: "installment_price_international",
  installment_type: "installment_type",
  payment_duration: "payment_duration",
  payment_duration_choice: "payment_duration_choice",
  priority: "priority",
  change_state: "change_state",
};

export const TIME_OF_INSTALLMENT_MAGNITUDE_LABEL = {
  [TIME_OF_INSTALLMENT.DAYS_FROM_PURCHASE]: "day",
  [TIME_OF_INSTALLMENT.WEEKS_FROM_PURCHASE]: "week",
  [TIME_OF_INSTALLMENT.MONTHS_FROM_PURCHASE]: "month",
};

export const TIME_OF_INSTALLMENT_MAP = {
  [TIME_OF_INSTALLMENT.DAYS_FROM_PURCHASE]: {
    label: "Days from Purchase",
    value: TIME_OF_INSTALLMENT.DAYS_FROM_PURCHASE,
  },
  [TIME_OF_INSTALLMENT.WEEKS_FROM_PURCHASE]: {
    label: "Weeks from Purchase",
    value: TIME_OF_INSTALLMENT.WEEKS_FROM_PURCHASE,
  },
  [TIME_OF_INSTALLMENT.MONTHS_FROM_PURCHASE]: {
    label: "Months from Purchase",
    value: TIME_OF_INSTALLMENT.MONTHS_FROM_PURCHASE,
  },
};

export const TIME_OF_INSTALLMENT_OPTIONS = [
  TIME_OF_INSTALLMENT_MAP[TIME_OF_INSTALLMENT.DAYS_FROM_PURCHASE],
  TIME_OF_INSTALLMENT_MAP[TIME_OF_INSTALLMENT.WEEKS_FROM_PURCHASE],
  TIME_OF_INSTALLMENT_MAP[TIME_OF_INSTALLMENT.MONTHS_FROM_PURCHASE],
];
