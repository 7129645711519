import React from "react";
import { Card } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import useStyles from "../../../schedule-v2.module";
import styles from "../../ReviewStyle.module.css";
import { is_empty } from "utils/validations";
import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";

const { getHtmlFromEditorState } = RichTextEditorUtils;

const Description = ({ description, title, is_desktop }) => {
  const htmlDescription = React.useMemo(() => {
    return description ? getHtmlFromEditorState(description) : null;
  }, [description]);
  const classes = useStyles({ is_desktop });

  if (is_empty(htmlDescription)) return null;

  return (
    <Card
      shadow="shadow_md"
      variant="outlined"
      CardClassName={classnames(classes.marginBottom, classes.paddedCard)}
    >
      <h5 className={classnames(styles.heading1, styles.mb)}>{title}</h5>
      <div className={styles.heading5}>
        <div dangerouslySetInnerHTML={{ __html: htmlDescription }} />
      </div>
    </Card>
  );
};

export default Description;
