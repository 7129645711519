import { lazy } from "react";

export const Coupons = lazy(() => import("ui/pages/discounts"));
export const Layout = lazy(() =>
  import("ui/layout").then((module) => ({ default: module.Layout }))
);
export const BrowserNotSupported = lazy(() =>
  import("ui/modules/BrowserNotSupported")
);
export const NotificationPopup = lazy(() =>
  import("ui/widgets/NotificationPopup")
);
export const TransactionsList = lazy(() =>
  import("ui/pages/transactions/AllTransaction")
);
export const AutomatedMessages = lazy(() =>
  import("ui/pages/communications/Customer")
);
export const Subscriptions = lazy(() =>
  import("ui/pages/transactions/Subscriptions/Subscriptions")
);
export const RefundHistory = lazy(() =>
  import("ui/pages/transactions/RefundHistory/RefundHistory")
);
export const WebpageLeads = lazy(() => import("../webpage-leads"));
export const NewListingCustomers = lazy(() =>
  import("ui/pages/customers/ManageBookings/SubscriptionCustomers")
);
export const AllCustomers = lazy(() =>
  import("ui/pages/customers/AllCustomers/AllCustomers")
);
export const ListingCustomers = lazy(() =>
  import("ui/pages/customers/ManageBookings/ListingCustomers")
);
export const DynamicLinkList = lazy(() =>
  import("ui/pages/payments/DynamicLinkList")
);
export const AdLeads = lazy(() => import("ui/pages/customers/Leads/AdLeads"));
export const ContentList = lazy(() =>
  import("ui/pages/schedule/RecordedContent/ContentList/index")
);
export const ManageRecordings = lazy(() =>
  import(
    "ui/pages/schedule/ManageOffering/pages/ManageRecordings/ManageRecordings"
  )
);
export const ListWhatsappTemplates = lazy(() =>
  import("ui/pages/whatsappBroadcast/list/ListWhatsappTemplates")
);
export const SalesPagesHome = lazy(() =>
  import("ui/pages/SalesPages/pages/SalesPagesHome/SalesPagesHome")
);
export const ActiveDrip = lazy(() =>
  import("ui/pages/communications/CustomEmail/ActiveDrip/ActiveDrip")
);
export const DraftDrip = lazy(() =>
  import("ui/pages/communications/CustomEmail/DraftDrip/DraftDrip")
);
export const PausedDrip = lazy(() =>
  import("ui/pages/communications/CustomEmail/PausedDrip/PausedDrip")
);
export const InActiveDrip = lazy(() =>
  import("ui/pages/communications/CustomEmail/InactiveDrip/InactiveDrip")
);
export const AnalyticsPerEmail = lazy(() =>
  import("ui/pages/EmailDrip/pages/AnalyticsPerEmail/AnalyticsPerEmail")
);
export const WebinarListingsAttendance = lazy(() =>
  import("ui/pages/ZoomAttendance/WebinarListingsAttendance")
);
export const ContentResponses = lazy(() =>
  import("ui/pages/ContentResponses/ContentResponses")
);
export const CampaignLeads = lazy(() =>
  import("ui/pages/customers/Leads/AdCampaignLeads")
);
export const CampaignCustomers = lazy(() =>
  import("ui/pages/customers/Leads/AdCampaignCustomers/index.js")
);
export const PastEmails = lazy(() =>
  import("ui/pages/communications/CustomEmail/PastEmails/PastEmails")
);
export const ListWhatsappBroadcasts = lazy(() =>
  import("ui/pages/whatsappBroadcast/list")
);
export const DraftEmails = lazy(() =>
  import("ui/pages/communications/CustomEmail/DraftEmails/DraftEmails")
);
export const ScheduleEmail = lazy(() =>
  import("ui/pages/communications/CustomEmail/ScheduledEmails/ScheduledEmails")
);
export const Logs = lazy(() => import("ui/pages/Organisation/Logs"));
export const UserReferrals = lazy(() =>
  import("ui/pages/marketing/UserReferrals")
);
export const Referrals = lazy(() => import("ui/pages/Referrals"));
export const AllSegments = lazy(() =>
  import("ui/pages/customers/Segments/AllSegments")
);
export const ExlyFeedbacks = lazy(() => import("ui/pages/ExlyFeedbacks"));
export const ManageFeedbacks = lazy(() =>
  import("ui/pages/ExlyFeedbacks/pages/ManageFeedbacks")
);
export const PublishedFeedbacks = lazy(() =>
  import("ui/pages/ExlyFeedbacks/pages/PublishedFeedbacks")
);
export const Blogs = lazy(() => import("ui/pages/communications/Blogs"));
export const ExlyFAQ = lazy(() => import("ui/pages/manageWebpage/FAQ"));
export const PaymentLinksTransactions = lazy(() =>
  import(
    "features/PaymentLinks/modules/PaymentLinksTransactions/PaymentLinksTransactions"
  )
);
export const Affiliates = lazy(() =>
  import("ui/pages/marketing/affiliates").then((module) => ({
    default: module.Affiliates,
  }))
);
export const LimitedOffer = lazy(() =>
  import("ui/pages/limitedOffer").then((module) => ({
    default: module.LimitedOffer,
  }))
);
export const OndemandExlyConnect = lazy(() =>
  import("features/ExlyConnect/pages/OndemandExlyConnect").then((module) => ({
    default: module.OndemandExlyConnect,
  }))
);
export const ScheduledExlyConnect = lazy(() =>
  import("features/ExlyConnect/pages/ScheduledExlyConnect").then((module) => ({
    default: module.ScheduledExlyConnect,
  }))
);
export const OfferingVariations = lazy(() =>
  import("features/OfferingVariations").then((module) => ({
    default: module.OfferingVariations,
  }))
);
export const CustomCodesWrapper = lazy(() =>
  import("features/CustomCode/modules/CustomCodesWrapper").then((module) => ({
    default: module.CustomCodesWrapper,
  }))
);
export const BonusOfferingCustomers = lazy(() =>
  import("ui/pages/customers/ManageBookings/BonusOfferingCustomers").then(
    (module) => ({ default: module.BonusOfferingCustomers })
  )
);
export const MySubscriptions = lazy(() =>
  import("ui/pages/Account/pages/MySubscriptions").then((module) => ({
    default: module.MySubscriptions,
  }))
);
export const SubscriptionTransactions = lazy(() =>
  import("ui/pages/Account/pages/SubscriptionTransactions").then((module) => ({
    default: module.SubscriptionTransactions,
  }))
);
export const Ledger = lazy(() =>
  import("ui/pages/Account/pages/Ledger").then((module) => ({
    default: module.Ledger,
  }))
);
export const Reports = lazy(() => import("ui/pages/Reports"));
export const BookedQuestions = lazy(() =>
  import("ui/pages/QuestionsPage/BookedQuestions")
);
export const OrganizationV3 = lazy(() =>
  import("ui/pages/OrganizationV3/pages/OrgMembers")
);
export const ZoomAttendanceHome = lazy(() =>
  import("ui/pages/ZoomAttendance/ZoomAttendanceHome")
);
export const ZoomAttendanceOfListing = lazy(() =>
  import("ui/pages/ZoomAttendance/ZoomAttendanceOfListing")
);
export const CourseAnalytics = lazy(() =>
  import("ui/pages/CourseAnalytics/CourseAnalytics")
);
export const Leaderboard = lazy(() =>
  import("ui/pages/Leaderboard/Leaderboard")
);

// Affiliate
export const AffliateTransactionsPayout = lazy(() =>
  import(
    "features/AffiliateMarketing/modules/AffliateTransactions/modules/TransactionPayout"
  ).then((module) => ({ default: module.AffliateTransactionsPayout }))
);
export const AffliateManagement = lazy(() =>
  import(
    "features/AffiliateMarketing/modules/AffliateManagement/AffliateManagement"
  ).then((module) => ({ default: module.AffliateManagement }))
);
export const NewAffiliateRequest = lazy(() =>
  import(
    "features/AffiliateMarketing/modules/NewAffiliateRequest/NewAffiliateRequest"
  ).then((module) => ({ default: module.NewAffiliateRequest }))
);
export const AffiliateDetails = lazy(() =>
  import(
    "features/AffiliateMarketing/modules/AffliateDetail/AffliateDetail"
  ).then((module) => ({ default: module.AffiliateDetails }))
);
export const AffliateTransactions = lazy(() =>
  import(
    "features/AffiliateMarketing/modules/AffliateTransactions/modules/AllTransactions/AffliateTransactions"
  ).then((module) => ({ default: module.AffliateTransactions }))
);
export const AffliateDiscount = lazy(() =>
  import(
    "features/AffiliateMarketing/modules/AffliateDiscount/AffliateDiscount"
  ).then((module) => ({ default: module.AffliateDiscount }))
);
export const OfferingWiseReport = lazy(() =>
  import(
    "features/AffiliateMarketing/modules/OfferingWiseReport/OfferingWiseReport"
  ).then((module) => ({ default: module.OfferingWiseReport }))
);
export const PayoutSummary = lazy(() =>
  import(
    "features/AffiliateMarketing/modules/PayoutSummary/PayoutSummary"
  ).then((module) => ({ default: module.PayoutSummary }))
);
