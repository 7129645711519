import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    "customLayout:parent": {
      padding: "0px !important",
    },

    lessonTitle: {
      color: theme?.palette?.primary?.main,
    },

    accordionRoot: {
      border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      boxShadow: "0px 3px 0px rgba(14, 13, 12, 0.14)",
      margin: "16px 0 !important",
    },

    collapsedContentClassName: {
      overflow: "visible",
    },

    disabledCheckIcon: {
      opacity: 0.25,
      color: "#524E47",
    },
    tipsSpan: {
      fontSize: "12px !important",
    },

    cursorPointer: {
      cursor: "pointer",
    },
    sessionsVisibleWrapper: {
      display: "flex",
      fontSize: "14px",
      gap: "5px",
      alignItems: "center",
      marginTop: "10px",
    },
    descriptionWrapperMobile: {
      height: "200px",
    },

    mt_18: {
      marginTop: "18px",
    },

    enrolledComment: {
      display: "flex",
      gap: "12px",
    },

    multipleDates: {
      display: "flex",
      width: "240px",
      justifyContent: "space-between",
    },

    filesTip: {
      display: "flex",
      width: "306px",
      justifyContent: "space-between",
      fontSize: "14px",
      paddingTop: "5px",
    },

    questionsTip: {
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      marginBottom: "5px",
      gap: "10px",
    },

    svgWrapper: {
      "& svg": {
        height: "21px",
        width: "21px",
      },
    },

    textWrapper: {
      fontSize: "14px",
    },

    switchRootWrapperClassName: {
      "&:hover": {
        borderColor: "transparent",
      },
    },

    infoBox: {
      display: "flex",
      fontSize: "14px",
      backgroundColor: "#493ab110",
      borderRadius: "6px",
      padding: "10px",
      marginTop: "12px",

      "& .icon": {
        color: "#493ab1",
        marginRight: "10px",
      },
    },

    pricingMutedText: {
      opacity: 0.7,
      fontSize: "14px",
      lineHeight: "17px",
      marginTop: "12px",
      color: "#272522",
    },

    modalPaper: {
      minWidth: ({ is_desktop }) => is_desktop && "600px",
    },

    textEditorPopup: {
      width: "100%",
    },

    colorBlack: {
      color: "black",
    },

    iconButton: {
      maxWidth: "50px !important",
    },

    customLayout: {
      display: "flex",
      height: ({ fullHeight }) => (fullHeight ? "100vh" : "calc(100vh - 50px)"),
      maxHeight: "100vh",
      position: "relative",
      backgroundColor: "#EEECF9",
    },

    descriptionHolder: {
      "& img": {
        maxWidth: "100%",
      },
    },

    customWrapperRTE: {
      width: ({ is_desktop }) => is_desktop && "590px",
    },

    unlockSection: {
      marginTop: "12px",
    },

    info: {
      marginTop: "10px",
      fontSize: "12px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      gap: "7px",
      color: theme?.palette?.secondary?.main,
      opacity: "0.6",
      "& svg": {
        fontSize: "16px",
      },
      userSelect: "none",
    },

    infoText: {
      fontSize: "14px",
      lineHeight: "17px",
      marginBottom: "16px",
    },

    waterMarkTitleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    watermarkDescription: {
      display: "flex",
      color: theme?.palette?.secondary?.main,
      opacity: "0.5",
      gap: "9.5px",
      fontSize: "14px",
      lineHeight: "19px",
      "& svg": {
        fontSize: "18px",
      },
      userSelect: "none",
    },

    descriptionHelperText: {
      fontSize: "14px",
      lineHeight: "17px",
      marginTop: "12px !important",
    },

    dropdownHelperText: {
      fontSize: "14px",
      lineHeight: "17px",
      marginTop: "12px !important",
      marginBottom: "0 !important",
      opacity: ({ is_desktop }) => (is_desktop ? 1 : 0.7),
    },

    gap_8: {
      gap: "8px",
    },

    optionsLabel: {
      height: "40px",
      display: "flex",
      alignItems: "center",
    },

    customLayout_onBoarding: {
      margin: "0",
    },

    time_range_input_wrap: {
      width: "77px",
    },

    customLayout_main: {
      flex: 1,
      padding: "108px 24px 0 24px",
      overflowY: "auto",
      scrollBehavour: "smooth",
    },

    customLayout_main_content: {
      maxWidth: "610px",
      margin: "0 auto",
      scrollBehavour: "smooth",
    },

    customLayout_main_content_wide: {
      maxWidth: "1100px",
      margin: "0 auto",
    },

    questionButtonWrapper: {
      marginTop: "14px",
    },

    questionCardTitleWrapper: {
      padding: "15px 20px",
      borderBottom: "2px solid #E7E6E4;",
    },
    cardTitleWrapper: {
      padding: "0px 0 14px 0",
    },

    customLayout_aside: {
      width: "400px",
      backgroundColor: "#FFF",
      padding: "80px 10px 10px 0",
      textAlign: "center",

      "& img": {
        width: "auto",
        maxHeight: "78%",
      },
    },

    progressWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 100,
      display: "flex",
      background: "#fff",
      width: "100%",
      alignItems: "center",
      padding: "12px 24px 12px 24px",
      borderBottom: "solid 2px #E7E6E4",
    },

    buttonWrapper: {
      display: "flex",
      width: "300px",
      justifyContent: "flex-end",
      alignItems: "center",
    },

    navWrapper: {
      display: "flex",
      width: "306px",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      alignItems: "center",
      cursor: "pointer",
      whiteSpace: "nowrap",
    },

    textPrimary: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 500,
      color: "#3e00c2",
    },

    cardPadding: {
      padding: "16px",
    },

    iconWrapper: {
      marginLeft: "8px",
    },
    actionButton: {
      padding: "1vw",
      marginTop: "0.3rem !important",
    },

    sectionCard: {
      padding: "16px",
      boxShadow: "0px 2px 0px rgba(14, 13, 12, 0.14)",
    },

    cardTitleContainer: {
      padding: "16px",
      fontWeight: 500,
      borderBottom: "1px solid #E7E6E4",
      display: "flex",
      alignItems: "center",
      gap: "4.5px",
    },

    cardBody: {
      padding: "16px",
    },

    cardTitle: {
      fontWeight: 500,
      fontSize: "16px",
    },

    cardTitleWithTick: {
      display: "flex",
      alignItems: "center",
    },

    zigzag: {
      background: "transparent",
      position: "relative",
      height: "16px",
      zIndex: 1,
      marginTop: "-26px",
      marginLeft: "-6px",
      marginRight: "-6px",
      marginBottom: "20px",

      "&:before, &:after": {
        content: '""',
        display: "block",
        position: "absolute",
        left: 0,
        right: 0,
      },

      "&:before": {
        height: "12px",
        top: "110%",
        background:
          "linear-gradient(-135deg, #e6e9f2 8px, transparent 0) 0 8px, linear-gradient(135deg, #e6e9f2 8px, transparent 0) 0 8px",
        backgroundPosition: "top left",
        backgroundRepeat: "repeat-x",
        backgroundSize: "16px 16px",
      },

      "&:after": {
        height: "16px",
        top: "100%",
        background:
          "linear-gradient(-135deg, #FFFFFF 8px, transparent 0) 0 8px, linear-gradient(135deg, #FFFFFF 8px, transparent 0) 0 8px",
        backgroundPosition: "top left",
        backgroundRepeat: "repeat-x",
        backgroundSize: "16px 16px",
      },
    },

    trimmedPaper: {
      maxWidth: "320px",
      fontSize: "14px",
      padding: "6px",
      marginTop: "15.39px",
      borderRadius: "6px",
      border: "solid 0.5px #e6e9f2",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      width: "50%",

      "& table": {
        width: "100%",
        borderCollapse: "collapse",

        "& tr": {
          "& td": {
            minWidth: "auto",

            "&:nth-child('2')": {
              textAlign: "right",
            },
          },
        },
      },
    },

    pricingCheckboxWrap: {
      marginTop: "2px",
    },

    accordionContent: {
      padding: "0px 20px 20px",
    },

    label: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: "500",
      color: "#272522",
    },

    cardHeading: {
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: "500",
      color: "#272522",
    },

    normalText: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: "400",
      color: "#272522",
    },

    highlightedText: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: "500",
      color: "#493AB1",
    },

    clickLink: {
      cursor: "pointer",
    },
    checkBoxText: {
      cursor: "pointer",
      fontSize: ({ is_desktop }) => (is_desktop ? "14px" : "12px"),
    },
    marginBottom: {
      marginBottom: "24px !important",
    },

    paddedCard: {
      padding: "20px 20px 24px 20px",
    },
    paddedCardSm: {
      padding: "16px",
    },
    subTitleDiv: {
      fontSize: "14px !important",
      marginLeft: "5px !important",
    },

    accordionSummaryRoot: {
      paddingLeft: "20px",
    },

    workshopTimePickerAnchor: {
      width: "150px",
      flexGrow: ({ is_desktop }) => !is_desktop && "1",
      padding: ({ is_desktop }) => !is_desktop && "10px",
    },

    workshopDatePickerWrapper: {
      width: "139px",
    },

    spacer: {
      marginBottom: "20px",
    },

    tipPadding: {
      paddingTop: "10px",
      paddingBottom: "20px",
    },

    batchBox: {
      borderBottom: "1.5px solid #D9D9D9",
      paddingBottom: "8px",
    },

    weekdayBordered: {
      paddingTop: "14px",
      "& + $weekdayBordered": {
        marginTop: "14px",
        borderTop: "1.5px solid #D9D9D9",
      },
    },

    currencySymbol: {
      marginLeft: "12.32px",
      marginRight: "8px",
      color: "red",
    },

    internationalUsersCheckboxContainer: {
      display: "flex",
      gap: "8px",
      marginTop: "18px",
    },

    checkboxDescription: {
      fontSize: "14px",
      color: "#272522",
    },

    modalPaperClass: {
      minWidth: "680px !important",
    },
    fieldWrapper: {
      margin: "9px auto",
    },
    questionsTitle: {
      marginBottom: "4px",
    },

    helperTextClassName: {
      marginTop: "12px !important",
      marginBottom: "0 !important",
      fontSize: "14px",
      color: "#272522",
      opacity: 0.7,
    },

    descriptionHeading: {
      fontSize: ({ is_desktop }) => (is_desktop ? "16px" : "14px"),
      fontWeight: 500,
      color: "#272522",
      marginBottom: "12px",
    },

    descriptionContentTitle: {
      display: "block",
      fontSize: ({ is_desktop }) => (is_desktop ? "14px" : "12px"),
      color: "#272522",
      opacity: 0.7,
    },

    checkboxWrapperClassName: {
      marginTop: "0.2rem",
    },

    pageTitle: {
      fontSize: "16px",
      fontWeight: 700,
      color: "#272522",
    },

    title: {
      fontSize: "14px",
      fontWeight: 500,
    },

    accordionTitle: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      "& span": {
        fontSize: ({ is_desktop }) => (is_desktop ? "16px" : "14px"),
      },
    },

    rteModalPlaceholder: {
      marginTop: "16px",
      padding: "12px",
      "& p": {
        marginTop: "0 !important",
        fontSize: "16px",
        color: "#B8B4AD",
      },
    },

    batchNumber: {
      display: "block",
      borderBottom: "1.5px solid #D9D9D9",
      paddingBottom: "8px",
    },

    appointmentTimeWrapper: {
      flexGrow: 0.5,
      display: "flex",
      flexDirection: "column",
      gap: ({ is_desktop }) => (is_desktop ? "22px" : "13px"),
    },

    appointmentActionButton: {
      marginTop: ({ is_desktop }) => (is_desktop ? "9px" : "5px"),
    },

    "@media (max-width:780px)": {
      trimmedPaper: {
        width: "100%",
      },
      marginBottom: {
        marginBottom: "20px !important",
      },
      paddedCard: {
        padding: "18px 14px 23px 14px",
      },
      weekdayBordered: {
        paddingTop: "6px",
        "& + $weekdayBordered": {
          marginTop: "14px",
          borderTop: "1.5px solid #D9D9D9",
        },
      },
      spacer: {
        marginBottom: "17px",
      },
      iconBtn: {
        padding: "3px",
      },

      cardTitle: {
        fontSize: "14px",
      },

      accordionSummaryRoot: {
        paddingLeft: "14px",
        fontSize: "14px",
      },

      workshopTimePickerAnchor: {
        width: "136px",
      },

      workshopDatePickerWrapper: {
        width: "131px",
      },

      customLayout: {
        margin: "unset",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1111,
        maxHeight: "calc(100vh - 66px)",
      },

      customLayout_main: {
        padding: "0 16px 63px 16px",
        overflowX: "hidden",
      },

      customLayout_main_onBoarding: {
        padding: "0px 16px 30px 16px",
      },

      customLayout_main_content: {
        maxWidth: "100%",
      },

      navWrapper: {
        justifyContent: "start",
        padding: "16px",
        fontSize: "16px",
        width: "100%",
        marginBottom: "16px",
        borderBottom: `2px solid #E7E6E4`,
      },

      progressWrapper: {
        flexDirection: "column",
        alignItems: "unset",
        zIndex: 100,
        padding: 0,
        paddingBottom: "16px",
        position: "sticky",
        width: "auto",
        margin: "0 -16px 16px -16px",
        top: 0,
      },
      progressWrapperOnboarding: {
        marginTop: "-10px",
      },

      buttonWrapper: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#fff",
        width: "100%",
        padding: "11.5px 16px",
        justifyContent: "space-between",
        borderTop: "solid 1px #ededed",
        zIndex: 20,
      },
    },
    switchWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: "145px",
      width: "100%",
      marginBottom: "10px",
    },

    xsIconButton: {
      padding: "3px",
      "&:hover": {
        background: "inherit",
      },
    },
    "@media (max-width:393px)": {
      xsDelete: {
        marginLeft: "2px !important",
      },
    },
    yellowNote: {
      backgroundColor: "#fff7e5 !important",
    },
    blueNoteUrl: {
      backgroundColor: "#E9FAFF !important",
      color: "#115064 !important",
    },
    merchandiseScreenshot: {
      background: "#EAE8FD",
      boxShadow: "inset 0px 3px 6px rgba(0, 0, 0, 0.08)",
      borderRadius: "22px",
      padding: "11px",
    },

    shippingCost: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      "& svg": {
        fontSize: "16px",
        marginTop: "-3px",
      },
    },

    tooltip: {
      marginTop: "-2px",
    },

    debitType: {
      display: "flex",
      paddingLeft: "8px",
    },

    debitTypeText: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "12px",
    },
    debitTypeTextTitle: {
      fontWeight: "500 !important",
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "0.02em",
      color: "rgba(0, 0, 0, 0.85)",
      paddingBottom: "4px",
    },

    debitTypeTextDesc: {
      fontWeight: "400",
      fontSize: "12px !important",
      lineHeight: "15px",
      letterSpacing: "0.02em",
      color: "rgba(39, 37, 34, 0.5) !important",
    },
    recommendedCheckbox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      padding: "0px 20px 20px",
    },
    recommendedCheckboxText: {
      fontSize: "14px",
      opacity: "0.8",
    },
    learnMore: { color: "#40329A", cursor: "pointer" },
    autoLessonCompText: {
      color: "#939290",
    },
    noteDesc: {
      display: "flex",
      gap: "9.5px",
      fontSize: "14px",
      lineHeight: "19px",
      "& svg": {
        fontSize: "18px",
      },
      userSelect: "none",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
