import React from "react";
import classnames from "classnames";
import { Card } from "@my-scoot/component-library-legacy";
import constants from "./Constants";
import { is_desktop } from "utils/Utils";
import Description from "schedule-v2/commonPages/ReviewSkeleton/components/Description";
import styles from "../commonPages/ReviewStyle.module.css";

const planConstants = {
  PLAN_DEFAULT:
    "https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/000000000000000000000_event_images%2Fcategory%2Fdefault%2Ftes.jpeg?alt=media&token=a4cb0783-5e41-4443-892f-7cafbdc9b201",
  HideIndividualListingTypes: [
    {
      value: false,
      label: "Make individual listing visible on website",
    },
    {
      value: true,
      label: "Hide individual listing from website",
    },
  ],
  FORM_KEYS: {
    COVER_IMAGE: "cover_image",
    TITLE: "title",
    LISTINGS: "listings",
    LISTING_MAP: "listing_map",
    OFFERING_TYPE: "offering_type",
    SHORT_DESCRIPTION: "short_description",
    DESCRIPTION: "description",
    HIDE_INDIVIDUAL_LISTING: "hide_individual_listing",
    ENABLE_RECOMMENDATIONS: "enable_recommendations",
  },
  STATUS_ENUM: {
    ACTIVE: 1,
    INACTIVE: 2,
    DRAFT: 3,
  },
  hide_listing_enum: {
    make_visible: 0,
    hide_listing: 1,
  },
  offering_change_state: {
    NO_CHANGE: 0,
    UPDATED: 1,
    NEW_ADDED: 2,
    DELETED: 3,
  },
  offering_status: {
    LIVE: "live",
  },
  NO_LIVE_OFFERING_PLAN_TEXT: "No live offerings available for this plan",
  EMPTY_OFFERING_TEXT: "You have no live offerings created yet.",
  NO_OFFERING_TEXT: "You have no offerings created yet.",
  DEFAULT_OFFERING_TYPE: 0,

  reviewConfig: ({ classes }) => {
    return {
      header: {
        custom: false,
        order: 1,
      },
      title: {
        custom: ({ title }) => {
          return (
            <Card
              shadow="shadow_md"
              variant="outlined"
              CardClassName={classnames(
                classes.marginBottom,
                classes.paddedCard
              )}
            >
              <>
                <h5 className={classnames(styles.heading1, styles.mb)}>
                  Plan name
                </h5>
                <div className={styles.heading3}>{title || ""}</div>
              </>
            </Card>
          );
        },
        order: 2,
      },
      offering: {
        custom: ({ hide_individual_listing, listings }) => {
          const recommended_offering = listings?.find(
            (value) => value?.is_recommended
          );

          const Visibility_status = hide_individual_listing
            ? constants.HideIndividualListingTypes[1]?.label
            : constants.HideIndividualListingTypes[0]?.label;

          return (
            <Card
              shadow="shadow_md"
              variant="outlined"
              CardClassName={classnames(
                classes.marginBottom,
                classes.paddedCard
              )}
            >
              <>
                <h5 className={classnames(styles.heading1, styles.mb)}>
                  Recommended Offering:{" "}
                  <span className={styles.heading3}>
                    {recommended_offering?.title}
                  </span>
                </h5>
                <div className={classnames(styles.heading1, styles.mb)}>
                  Visibility Status:{" "}
                  <span className={styles.heading3}>{Visibility_status}</span>
                </div>
              </>
            </Card>
          );
        },
        order: 3,
      },
      selected_offering: {
        custom: ({ listings }) => {
          return (
            <Card
              shadow="shadow_md"
              variant="outlined"
              CardClassName={classnames(
                classes.marginBottom,
                classes.paddedCard
              )}
            >
              <>
                <h5 className={classnames(styles.heading1, styles.mb)}>
                  Offerings In The Plan:
                </h5>
                {listings?.map((value) => {
                  return (
                    <div key={value?.uuid} className={styles.heading3}>
                      {value?.title || ""}
                    </div>
                  );
                })}
              </>
            </Card>
          );
        },
        order: 4,
      },
      plan_description: {
        custom: ({ description }) => {
          return (
            <Description
              title={"Plan Description"}
              description={description}
              is_desktop={is_desktop}
            />
          );
        },
        order: 5,
      },
    };
  },
  PREV_ACTIVE_TAB: {
    key: "prev_active_tab",
  },
  ALL: "all",
};
export default planConstants;
