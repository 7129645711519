import { customer_questions_constants } from "constants/customerQuestions.constants";
import InfoIcon from "@material-ui/icons/InfoOutlined";

export const noLayoutRoutes = ["ads"];

export const ihf_factor_when_beared_by = {
  creator: 0,
  customer: 1,
};

export const listing_repeat_interval_types = {
  once: 1, // for dated appointments and workshop
  weekly: 2, //for weekly appointment and classes
};

export const listing_session_conflict_types = {
  overlapping_sessions: "overlapping_sessions",
  rescheduled_sessions: "rescheduled_sessions",
  unavailabilities: "unavailabilities",
};

export const appointment_availability_types = [
  {
    id: customer_questions_constants.avaibility_type.everyday,
    value: customer_questions_constants.avaibility_type.everyday,
    label: "Every Day",
  },
  {
    id: customer_questions_constants.avaibility_type.single_day,
    value: customer_questions_constants.avaibility_type.single_day,
    label: "Single Day",
  },
  {
    id: customer_questions_constants.avaibility_type.weekday,
    value: customer_questions_constants.avaibility_type.weekday,
    label: "Every Weekday (Monday to Friday)",
  },
  {
    id: customer_questions_constants.avaibility_type.custom,
    value: customer_questions_constants.avaibility_type.custom,
    label: "Custom",
  },
  {
    id: customer_questions_constants.avaibility_type.not_decided,
    value: customer_questions_constants.avaibility_type.not_decided,
    label: "Not Sure, Set After Booking",
  },
];

export const appointment_start_time_exception_type_options = [
  {
    id: 1,
    label: "Minutes",
    value: 1,
  },
  {
    id: 2,
    label: "Hours",
    value: 2,
  },
  {
    id: 3,
    label: "Days",
    value: 3,
  },
];

export const appointment_slot_range_type_options = [
  {
    id: 1,
    label: "Days",
    value: 1,
  },
  {
    id: 2,
    label: "Weeks",
    value: 2,
  },
];

export const getWaterMarkingInfoText = ({
  onlyFrontendHandledWatermarking,
  is_video_drm_enabled,
}) => {
  let supportedFormats;

  if (is_video_drm_enabled && onlyFrontendHandledWatermarking) {
    supportedFormats = "images";
  } else if (is_video_drm_enabled) {
    supportedFormats = "images and PDFs";
  } else if (onlyFrontendHandledWatermarking) {
    supportedFormats = "videos & images";
  } else {
    supportedFormats = "images, videos, and PDFs";
  }

  return `Watermarking ensures that any unauthorised sharing can be traced back to the source. A unique watermark featuring your customer's email address will be subtly applied to ${supportedFormats} when enabled. To enable watermarking, turn on the toggle.`;
};

/* /=====/ Webinar /====/ */

export const webinarValueMapping = {
  liveWebinar: 1,
};

// Enums
export const webinar_enums = {
  // Webinar Type
  LIVE_WEBINAR: "LIVE_WEBINAR",
  PRE_RECORDED: "PRE_RECORDED",

  // Frequency types
  ONE_TIME: "ONE_TIME",
  ON_SELECTED_DATES: "ON_SELECTED_DATES",
  REPEAT_ON_SELECTED_DAYS_OF_THE_WEEK: "REPEAT_ON_SELECTED_DAYS_OF_THE_WEEK",
};

export const webinar_labels = {
  // Webinar Type
  [webinar_enums.LIVE_WEBINAR]: "Live Webinar",

  // Frequency types
  [webinar_enums.ONE_TIME]: "One time",
  [webinar_enums.ON_SELECTED_DATES]: "On Selected Dates",
  [webinar_enums.REPEAT_ON_SELECTED_DAYS_OF_THE_WEEK]:
    "Repeat On selected days of the week",
};

export const webinar_types = [
  {
    id: webinar_enums.LIVE_WEBINAR,
    value: webinarValueMapping.liveWebinar,
    label: webinar_labels[webinar_enums.LIVE_WEBINAR],
  },
  // Needed for phase 2
  // {
  //   id: "Pre Recorded",
  //   value: 2,
  //   label: "Pre Recorded",
  // },
];

export const webinarFrequencyLabelMapping = {
  1: webinar_labels[webinar_enums.ONE_TIME],
  5: webinar_labels[webinar_enums.ON_SELECTED_DATES],
  6: webinar_labels[webinar_enums.REPEAT_ON_SELECTED_DAYS_OF_THE_WEEK],
};

export const webinar_frequency_types = [
  {
    value: customer_questions_constants.avaibility_type.single_day,
    label: webinar_labels[webinar_enums.ONE_TIME],
  },
  {
    value: customer_questions_constants.avaibility_type.occur_on_selected_dates,
    label: webinar_labels[webinar_enums.ON_SELECTED_DATES],
  },
  {
    value:
      customer_questions_constants.avaibility_type
        .occur_on_selected_days_of_week,
    label: webinar_labels[webinar_enums.REPEAT_ON_SELECTED_DAYS_OF_THE_WEEK],
  },
];

export const zoom_meeting_type = {
  zoom: 1,
  zoom_webinar: 2,
};

export const video_title_length = {
  min: 3,
  max: 100,
};

export const public_url_note = {
  image: InfoIcon,
  info: "<span>Video will be shown as a Video player in the course and not as a link.</span>",
};

export const auto_lesson_comp_bullets_points = [
  {
    id: 1,
    section: "Video",
    desc: "When customer has watched 80% of the video",
  },
  {
    id: 2,
    section: "Image / PDF / Text",
    desc: "When customer has viewed once",
  },
  {
    id: 3,
    section: "File",
    desc: "When customer has downloaded the item Link",
  },
  {
    id: 4,
    section: "Live Session",
    desc: "When customer has clicked on the link to open",
  },
  { id: 5, section: "Exam", desc: "When customer has submitted the exam" },
  { id: 6, section: "Form", desc: "When customer has submitted the exam" },
];

export const ORDER_PAYMENT_TYPE = {
  ONE_TIME_PAYMENT: 1,
  INITIAL_PART_PAYMENT: 2,
  PART_PAYMENT_INSTALLMENTS: 3,
  SUBSCRIPTION: 4,
};

export const ORDER_PAYMENT_TYPE_LABELS = {
  [ORDER_PAYMENT_TYPE.ONE_TIME_PAYMENT]: "One-time payment",
  [ORDER_PAYMENT_TYPE.INITIAL_PART_PAYMENT]: "Instalments",
  [ORDER_PAYMENT_TYPE.PART_PAYMENT_INSTALLMENTS]: "Instalments",
  [ORDER_PAYMENT_TYPE.SUBSCRIPTION]: "Subscription",
};

export const SCHEDULE_ONBOARDING_KEY = "onboarding";
