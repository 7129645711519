import { questions_type_mapping } from "../../../../constants/customerQuestions.constants";

export const question_initial_state = {
  question: "",
  type: 1,
  question_placement: 2,
  is_mandatory: true,
  choices: [],
};

const displayArrayForObject = (obj, keysToBundle) => {
  if (!obj || !keysToBundle) return [];
  return Object.keys(obj).map((key) => {
    return Object.keys(obj[key])
      .filter((childKey) => keysToBundle.includes(childKey))
      .reduce((cur, childKey) => {
        return Object.assign(cur, { [childKey]: obj[key][childKey] });
      }, {});
  });
};

export const question_placement_types = [
  {
    id: 1,
    value: 1,
    label: "Before Booking",
  },
  {
    id: 2,
    value: 2,
    label: "After Booking",
  },
];

export const file_types = [
  {
    id: 1,
    value: "image/*",
    label: "Image",
  },
  {
    id: 2,
    value: "application/pdf",
    label: "PDF",
  },
  {
    id: 3,
    value: "video/*",
    label: "Video",
  },
];

export const answer_types = () =>
  displayArrayForObject(questions_type_mapping, ["id", "value", "label"]);

export const question_option_choices_key = "questionObj.choices";
