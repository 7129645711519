import { is_empty } from "utils/validations";
import { variantDetailsFormKeys } from "./Modals/VariantDetailsModal/data";
import { change_states } from "constants/recordedContent";
import { checkObjectHasEqualValues } from "utils/object";

export const getVariantTags = (variant) =>
  is_empty(variant) ? [] : Object.values(variant);

export const isSingleTag = (variant) => Object.keys(variant).length === 1;

export const getVariantLabel = (variant, index) =>
  isSingleTag(variant) ? Object.keys(variant)[0] : `Variation ${index + 1}`;

export const getVariantIndex = (variations = [], variation) =>
  variations.findIndex(
    ({ [variantDetailsFormKeys.VARIANT_MAP]: currentVariation }) =>
      checkObjectHasEqualValues(currentVariation, variation)
  );

export const getHasVariants = (variations) =>
  !is_empty(
    variations?.filter(
      (variation) =>
        variation.uuid &&
        variation[variantDetailsFormKeys.CHANGE_STATE] !== change_states.deleted
    )
  );

export const getVariantData = (variation, variations) =>
  variations.find(
    ({
      [variantDetailsFormKeys.VARIANT_MAP]: currentVariation,
      [variantDetailsFormKeys.CHANGE_STATE]: changeState,
    }) =>
      checkObjectHasEqualValues(currentVariation, variation) &&
      changeState !== change_states.deleted
  ) || {};
