import { maxLength, minLength, required } from "common/validate";
import {
  accepted_image_formats,
  max_uploadable_file_size_in_mb,
} from "constants/recordedContent";
import { fileTypeIsAnyOfMIMETypes } from "utils/fileUtils";
import { composeValidators, is_empty, validateURL } from "utils/validations";
import {
  dot_accepted_formats,
  image_popup_tab_ids,
  max_file_name_length,
  min_file_name_length,
} from "./AddImageModal.constants";
import { file_mime_types } from "features/Common/modules/File/File.constants";

export const validateSelectedFile = (file = {}) => {
  const fileSizeInMB = (file.size || 0) / (1024 * 1024);
  if (fileSizeInMB > max_uploadable_file_size_in_mb)
    return `Maximum file size allowed is ${max_uploadable_file_size_in_mb}MB`;

  if (!fileTypeIsAnyOfMIMETypes(file.type, accepted_image_formats))
    return `Only acceptable formats are: ${dot_accepted_formats.join(", ")}`;

  const fileName = file.name;
  if (is_empty(fileName)) return "Please upload a file";
};

export const validateFileTitle = composeValidators(
  required,
  minLength(min_file_name_length, "Title must be atleast 3 characters"),
  maxLength(max_file_name_length, "Title must be atmost 100 characters")
);

export const validateForm = ({
  activeTab,
  imageTitle,
  imageUrl,
  uploadedUrl,
}) => {
  const isValidUserInputtedFileName = validateFileTitle(imageTitle);
  if (isValidUserInputtedFileName) {
    return isValidUserInputtedFileName;
  }

  if (activeTab === image_popup_tab_ids.upload) {
    if (is_empty(uploadedUrl)) return "Please upload a file";
  }

  if (activeTab === image_popup_tab_ids.paste_url) {
    return validateURL(imageUrl);
  }
};

export const isFileMimeTypeImage = (fileType) => {
  return accepted_image_formats.some(
    (format) => file_mime_types[format] == fileType
  );
};
