import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classnames from "classnames";
import { IconButton, InputAdornment } from "@material-ui/core";
import Dots from "@material-ui/icons/DragIndicator";
import DeleteIconLined from "assets/images/delete_lined.svg";
import useStyles from "../../../schedule-v2.module";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useForm } from "react-final-form";
import { is_empty, isInteger } from "utils/validations";
import { Input } from "@my-scoot/component-library-legacy";
import { Tooltip } from "@my-scoot/component-library-legacy";
import InfoOutlined from "assets/vectors/tooltip_icon_small.svg";
import { questions_type_mapping } from "constants/customerQuestions.constants";
import styles from "./AddQuestions.module.css";
import { question_option_choices_key } from "./questionFormConstants";

const AddQuestions = ({ data, type }) => {
  const [inputs, set_inputs] = React.useState([]);

  const is_desktop = useDesktopMediaQuery();
  const classes = useStyles({ is_desktop });
  const form = useForm();

  const isMoreThanOneInputOption = React.useMemo(
    () => inputs.length > 1,
    [inputs]
  );

  React.useEffect(() => {
    if (is_empty(data)) {
      set_inputs([
        { value: "", index: 0 },
        { value: "", index: 1 },
      ]);
    } else {
      set_inputs(data?.map((item, index) => ({ value: item, index })));
    }
  }, []);

  React.useEffect(() => {
    if (is_empty(data)) {
      set_inputs([
        { value: "", index: 0 },
        { value: "", index: 1 },
      ]);
    } else {
      set_inputs(data?.map((item, index) => ({ value: item, index })));
    }
  }, [data]);

  const handleChange = (value, i) => {
    let temp = inputs;
    temp[i].value = value;
    set_inputs([...temp]);

    form.change(
      question_option_choices_key,
      temp.map((item) => item.value)
    );
  };

  const addMoreOptions = () => {
    const newInputs = [...inputs, { value: "", index: inputs.length }];
    set_inputs([...newInputs]);
    form.change(
      question_option_choices_key,
      newInputs.map((item) => item.value)
    );
  };

  const handleDeleteInputClick = (idx) => {
    if (isMoreThanOneInputOption) {
      const newInputs = inputs.filter((_, index) => idx !== index);
      set_inputs([...newInputs]);
      form.change(
        question_option_choices_key,
        newInputs.map((item) => item.value)
      );
    }
  };

  const handleDragDropEnd = (result) => {
    if (
      !isInteger(result?.destination?.index) ||
      !isInteger(result?.source?.index)
    ) {
      return;
    }
    let newInputs = [...inputs];
    let reorderedData = newInputs.splice(result.source.index, 1);
    newInputs.splice(result.destination.index, 0, ...reorderedData);

    set_inputs([...newInputs]);
    form.change(
      question_option_choices_key,
      newInputs.map((item) => item.value)
    );
  };

  // Selecting title of the tooltip based on question type
  const toolTipTitle = questions_type_mapping[type].tooltip;

  return (
    <>
      <div>
        <span className={classes.questionsTitle}>Input Options</span>
        <Tooltip arrow color="primary" placement="top" title={toolTipTitle}>
          <img
            src={InfoOutlined}
            alt={"icon_button"}
            className={classes.iconWrapper}
            height={"14px"}
            width={"14px"}
          />
        </Tooltip>
      </div>

      <div className={styles.optionContainerBoxStyles}>
        <DragDropContext onDragEnd={handleDragDropEnd}>
          <Droppable
            droppableId="booking-questions-options"
            type="BOOKING_QUESTIONS"
            mode="standard"
          >
            {(provided) => {
              return (
                <div ref={provided.innerRef}>
                  {inputs.map((item, idx) => {
                    let i = item.index;
                    return (
                      <Draggable
                        key={`booking-questions-options-${i}`}
                        draggableId={`booking-questions-options-${i}`}
                        index={idx}
                      >
                        {(provided) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className={styles.optionContainerStyles}>
                                <div
                                  className={classnames(
                                    "d-md-block mb-2 mt-2",
                                    styles.inputContainerStyle
                                  )}
                                >
                                  <Input
                                    value={item.value}
                                    onChange={(e) =>
                                      handleChange(e.target.value, i)
                                    }
                                    size="small"
                                    placeholder="Enter option..."
                                    fullWidth={true}
                                    endAdornment={
                                      isMoreThanOneInputOption && (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() =>
                                              handleDeleteInputClick(idx)
                                            }
                                          >
                                            <img
                                              src={DeleteIconLined}
                                              alt="delete button icon"
                                            />
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }
                                  />
                                </div>
                                {isMoreThanOneInputOption && provided && (
                                  <div
                                    className={styles.dotsIconStyles}
                                    {...provided.dragHandleProps}
                                  >
                                    <Dots />
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>

      <Button
        onClick={() => addMoreOptions()}
        color="secondary"
        variant="outlined"
        fullWidth={true}
      >
        + Add More Options
      </Button>
    </>
  );
};

export default AddQuestions;
