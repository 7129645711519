export const MERCHANDISE_DEFAULT_IMAGE =
  "https://images.unsplash.com/photo-1462392246754-28dfa2df8e6b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c3RvcmV8ZW58MHx8MHx8&auto=format&fit=crop&w=1470&q=80";

// react-final-form will contains these keys and can be accessed anywhere within form
export const variantsFormKeys = {
  VARIANTS_DISPLAY_DATA: "variants_display_data",
  VARIANTS_DATA: "variants_data",
  VARIATIONS: "variations", // array containing all added or to be added variants in db
  POSSIBLE_VARIATIONS: "possibleVariations", // array containing all possible variations generated from all available variant attributes (cartesian product of variant type options)
  VARIANTS: "variants",
};

export const priceAndStockDetailsFormKeys = {
  PRICE: "price_per_head",
  PRICE_INTERNATIONAL: "price_international",
  TOTAL_INVENTORY: "total_inventory",
  ALLOW_OVERFLOW: "allow_overflow",
};
