import React from "react";
import classnames from "classnames";
import { useStyles } from "../variation.styles";

const VariationHeader = ({ title, options }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.titleContainer)}>
      <span className={classes.title}>{title}</span>

      <div className={classes.options}>
        {options.map((option) => (
          <div key={option}>{option}</div>
        ))}
      </div>
    </div>
  );
};

export default VariationHeader;
