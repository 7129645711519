export const shippingFormKeys = {
  ENABLE_SHIPROCKET: "enable_shiprocket",
  SHIPPING_DETAILS: "shipping_details",
  SHIPPING_METHOD: "shipment_method",
  SHIPPING_CHARGE: "shipping_charge",
  SHIPPING_CHARGE_INTERNATIONAL: "shipping_charge_international",
  SHIPPING_DAYS: "shipping_days",
  SHIPPING_DAYS_INTERNATIONAL: "shipping_days_international",
  SHIPROCKET_ADDRESS_ID: "shiprocket_address_id",
};

export const MerchandiseShipmentMethod = {
  AUTO: 1,
  MANUAL: 2,
};
