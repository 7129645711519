import { is_empty } from "utils/validations";
import {
  INSTALLMENTS_KEYS,
  PART_PAYMENT_FORM_KEYS,
} from "../PaymentPlanWithInstallments.data";
import { PAYMENT_PLAN_TYPE } from "constants/schedule";

/**
 * Checks if a given payment plan type is equal to 'INSTALLMENTS'.
 *
 * @param {string} planType - The payment plan type to be checked.
 * @returns {boolean} True if the planType is 'INSTALLMENTS', otherwise false.
 */
export const isInstallmentsPlanType = (planType) =>
  planType === PAYMENT_PLAN_TYPE.INSTALLMENTS;

/**
 * Checks if the given plan type is a subscription plan type.
 * @param {string} planType - The plan type to check.
 * @returns {boolean} Returns true if the plan type is a subscription plan type, otherwise false.
 */
export const isSubscriptionPlanType = (planType) =>
  planType === PAYMENT_PLAN_TYPE.SUBSCRIPTIONS;

export const formFormatters = {
  [PART_PAYMENT_FORM_KEYS.payment_plan_type]: (value) =>
    value === PAYMENT_PLAN_TYPE.INSTALLMENTS,
};

export const formParsers = {
  [PART_PAYMENT_FORM_KEYS.payment_plan_type]: (value) =>
    value ? PAYMENT_PLAN_TYPE.INSTALLMENTS : PAYMENT_PLAN_TYPE.ONE_TIME_PAYMENT,
};

export const processInstallmentsChange = (formState) => {
  const { [PART_PAYMENT_FORM_KEYS.installment_data]: installments } = formState;

  if (is_empty(installments)) return;

  return installments.filter(
    (installment) => !is_empty(installment?.[INSTALLMENTS_KEYS.change_state])
  );
};
