export const variantDetailsFormKeys = {
  PRICE: "price_per_head",
  INTERNATIONAL_PRICE: "price_international",
  TOTAL_INVENTORY: "total_inventory",
  ALLOW_OVERFLOW: "allow_overflow",
  IMAGES: "images",
  VARIANT_MAP: "variant_map",
  CHANGE_STATE: "change_state",
};

export const variantChangeStates = {
  NO_CHANGE: 0,
  UPDATED: 1,
  NEW_ADDED: 2,
  DELETED: 3,
};
